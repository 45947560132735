<template>
  <div class="wait_relevance_home_page">
    <myTable title="待入校关联" :columns="columns" :list="list">
    </myTable>
  </div>
</template>

<script>
  import myTable from '@/components/admin/table.vue'
  export default {
    name: 'waitRelevance',
    components: {
      myTable
    },
    data() {
      return {
        //班级列表
        columns: [
        {
          prop: 'schoolName',
          label: '编号',
        },
        {
          prop: 'city',
          label: '家长姓名',
        },
        {
          prop: 'sex',
          label: '孩子姓名',
        },
        {
          prop: 'contacts',
          label: '手机号',
        },
        {
          prop: 'contactsMobile',
          label: '家长所填班级',
        },
        {
          prop: 'elite',
          label: '分配班级',
        }],
        list: [],

      }
    },
    created() {},
    mounted() {
      this.init()
    },
    methods: {
      //初始化
      init() {
        this.getTeacherInfo()
      },
      getTeacherInfo() {
          //todo
        this.api.admin.atteSchool(this.$store.state.userInfo.schoolId).then((res) => {
          console.log(res)
          
        })
      },
      onSubmit(val) {
        console.log(val)
        // 新增
        if (this.alertStatus === 'add') {
          this.api.admin.addStudent(val).then((res) => {
            console.log(res)
            if (res.flag) {
              this.$refs.comdialog.isClose()
            }
            this.$notice({
              type: 'success',
              message: res.message
            }).isShow()
          })
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  .wait_relevance_home_page {

  }
</style>